/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d5787e28-38b0-4bab-aa8c-c2f1a9ddfada",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rgM6dJXdO",
    "aws_user_pools_web_client_id": "16vf9nrann4au1s879mo0ddbnc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "LokisPlaygroundProductsAPI",
            "endpoint": "https://8n0kkaiqz4.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "LokisPlaygroundUpdateProductStarsAPI",
            "endpoint": "https://8b9kn00gge.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "LokisPlaygroundSubscriptionAPI",
            "endpoint": "https://bgfjilk040.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
